import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import { addRolesEmployee, deletRolesEmployee, getPositions, getRolesEmployee } from '../../utilities/sendRequest';

const CardRoles = ({ nip, fetchAgain, setFetchAgain }) => {
    const [isReady, setIsReady] = useState(false)
    const [fetchMore, setFetchMore] = useState(false)

    const [position, setPosition] = useState("")
    const [positions, setPositions] = useState([])
    const [datas, setDatas] = useState([])

    useEffect(() => {
        fetchRoles();
        fetchPositions();
        setFetchMore(false)
        setFetchAgain(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchMore, fetchAgain])

    useEffect(() => {
        const isReady = (position !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position])

    const fetchRoles = async () => {
        getRolesEmployee(nip).then((res) => {
            setDatas(
                res.payload.sort((p1, p2) => {
                    return new Date(p2.User_Position.createdAt) - new Date(p1.User_Position.createdAt)
                })
            )
            console.log(res.payload);
            
            
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const fetchPositions = async () => {
        getPositions().then((res) => {
            setPositions(res.payload)
        }).catch((err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const columns = [
        { 
            field: 'title', 
            headerName: 'Roles',
            width: 300, 
            renderCell: (params) => {
                return (
                    <div className='flex justify-center items-center font-ssp'>
                        {params.row.title}
                    </div>
                )
            } 
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => {
                return (
                    <div className='w-full flex flex-row gap-3 items-center'>
                        <DeleteIcon fontSize='medium' className="text-red-400 hover:text-red-500 cursor-pointer" onClick={() => handleDelete(params.row.id)}/>
                    </div>
                )
            }
        },
    ]

    const handleDelete = async (paramsId) => {
        deletRolesEmployee(paramsId).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setFetchMore(true)
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
    
        setIsReady(false)

        const reqData = {
            slug: position,
        }

        addRolesEmployee(nip, reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            setFetchMore(true)
            handleReset();
        }).catch((err) => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
        })
    }

    const handleReset = () => {
        setPosition("")
        setPositions([])
    }

    return (
        <div className='my-5 mx-7 md:mx-5 flex flex-col lg:flex-row justify-evenly gap-7 md:gap-10'>
            {/* list data */}
            <div className='w-full h-full lg:w-[60%] flex flex-col gap-3 items-center'>
                <div className="p-2 w-full flex bg-white drop-shadow-lg rounded-md">
                    <DataGrid
                        autoHeight {...datas}
                        rows={datas}
                        columns={columns}
                        pageSize={5}
                        getRowId={(row) => row.id}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                        components={{ Toolbar: GridToolbar, }}
                    />
                </div>
            </div>

            {/* form create */}
            <div className="px-2 py-4 w-full h-fit lg:w-[40%] bg-white drop-shadow-lg rounded-md flex flex-col gap-3">
                <h1 className='font-semibold text-base font-ssp px-3'>Add Roles</h1>
                <hr className='px-3'/>
                <div className=" py-1 w-full h-fit flex">
                    <form onSubmit={handleSubmit} className='px-5 w-full flex flex-col justify-between'>
                        {/* positions */}
                        <div className="mt-3 mb-6 w-full relative z-0 group">
                            <select 
                                required 
                                value={position} 
                                onChange={(e) => setPosition(e.target.value)} 
                                id="position" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer"
                            >
                                <option value="">Choose a Roles</option>
                                {positions.map((option) => (
                                    <option key={option.slug} value={option.slug}>{option.title}</option>
                                ))}
                            </select>
                            <label 
                                htmlFor="position" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Roles</label>
                        </div>
                        {/* button */}
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                disabled={!isReady}
                                className={`mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center ${isReady ? 'text-white bg-green-400 hover:bg-green-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'}`}
                            >Add</button>
                            <button 
                                type="button" 
                                onClick={handleReset}
                                className="mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center text-white bg-red-400 hover:bg-red-500 cursor-pointer"
                            >Reset</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

CardRoles.propTypes = {
    nip: PropTypes.string,
    setShowModalFamilyAddress: PropTypes.func,
    fetchAgain: PropTypes.bool,
    setFetchAgain: PropTypes.func,
}

export default CardRoles