import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoIosCloseCircle } from 'react-icons/io';
import { addEmployee, getEchelons, getOffices } from '../../utilities/sendRequest';

const CreateEmployee = () => {
    const photoRef = useRef(null)
    const navigate = useNavigate()
    const [isReady, setIsReady] = useState(false)
    const [photo, setPhoto] = useState("")
    const [fullname, setFullname] = useState("")
    const [nip, setNip] = useState("")
    const [email, setEmail] = useState("")
    const [birthday, setBirthday] = useState("")
    const [hireDate, setHireDate] = useState("")
    const [hometown, setHometown] = useState("")
    const [religion, setReligion] = useState("")
    const religionOptions = [
        {
            label: "ISLAM",
            value: "ISLAM"
        },
        {
            label: "KRISTEN",
            value: "KRISTEN"
        },
        {
            label: "KATOLIK",
            value: "KATOLIK"
        },
        {
            label: "HINDU",
            value: "HINDU"
        },
        {
            label: "BUDDHA",
            value: "BUDDHA"
        },
        {
            label: "KHONGHUCU",
            value: "KHONGHUCU"
        },
    ]
    const [lastEducation, setLastEducation] = useState("")
    const [nik, setNik] = useState("")
    const [marital, setMarital] = useState("")
    const maritalOptions = [
        {
            label: "SINGLE",
            value: "SINGLE",
        },
        {
            label: "MARRIED",
            value: "MARRIED"
        },
        {
            label: "DIVORCE",
            value: "DIVORCE"
        },
    ]
    const [gender, setGender] = useState("")
    const genderOptions = [
        {
            label: "PRIA",
            value: "PRIA"
        },
        {
            label: "WANITA",
            value: "WANITA"
        },
    ]
    const [office, setOffice] = useState("")
    const [offices, setOffices] = useState([])
    const [echelon, setEchelon] = useState("")
    const [echelons, setEchelons] = useState([])
    const [status, setStatus] = useState("")
    const statusOptions = [
        {
            label: "ACTIVE",
            value: true
        },
        {
            label: "INACTIVE",
            value: false
        },
    ]
    
    useEffect(() => {
        fetchOffices()
        fetchEchelons()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchOffices = async () => {
        getOffices().then((res) => {
            setOffices(res.payload.filter(office => office.is_active === true))
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }
    
    useEffect(() => {
        const isReady = (fullname !== "" && nik !== "" && religion !== "" && marital !== "" && office !== "" && gender !== "" && echelon !== "" && status !== "");
        setIsReady(isReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullname, nik, religion, marital, office, gender, echelon, status])

    const fetchEchelons = async () => {
        getEchelons().then((res) => {
            setEchelons(res.payload)
        }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        })  
    }

    const handleResetPreview = () => {
        setPhoto(null)
        photoRef.current.value = null;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsReady(false)
        const reqData = new FormData();
        reqData.append("fullname", fullname);
        reqData.append("nip", nip);
        reqData.append("email", email);
        reqData.append("birthday", birthday);
        reqData.append("hire_date", hireDate);
        reqData.append("hometown", hometown);
        reqData.append("religion", religion);
        reqData.append("last_education", lastEducation);
        reqData.append("id_card", nik);
        reqData.append("marital_status", marital);
        reqData.append("gender", gender);
        reqData.append("office_slug", office);
        reqData.append("echelon_code", echelon);
        reqData.append("is_active", status);

        if(photo) {
            reqData.append("photo", photo);
        }

        addEmployee(reqData).then((res) => {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            
            handleResetPreview()
            navigate('/employees')
        }).catch((error) => {
            console.log(error);
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }).finally(() => {
            setIsReady(true)
        })
    }

    return (
        <div className='min-h-[calc(100vh-4rem)] flex flex-col'>
            {/* header */}
            <div className="my-5 mx-7 md:mx-5 flex flex-row justify-between">
                <h1 className='font-ssp font-bold text-4xl md:text-3xl'>Create Employee</h1>
            </div>
            {/* content */}
            <div className="my-5 mx-7 md:mx-5 flex flex-col lg:flex-row gap-10">
                {/* form update */}
                <div className="px-2 py-4 w-full flex bg-white drop-shadow-lg rounded-md">
                    <form onSubmit={handleSubmit} className='px-5 py-3 w-full flex flex-wrap justify-between'>
                        {/* nip */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <input 
                                value={nip} 
                                onChange={(e) => setNip(e.target.value)} 
                                type="text" 
                                name="nip" 
                                id="nip" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="nip" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >NIP</label>
                        </div>
                        {/* fullname */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <input 
                                minLength={3} 
                                value={fullname} 
                                onChange={(e) => setFullname(e.target.value)} 
                                type="text" 
                                name="fullname" 
                                id="fullname" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent 
                                            border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 
                                            focus:border-blue-600 peer" 
                                placeholder=" " 
                                required 
                            />
                            <label 
                                htmlFor="fullname" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Fullname</label>
                        </div>
                        {/* email */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <input 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)} 
                                type="email" 
                                name="email" 
                                id="email" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="email" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Email</label>
                        </div>
                        {/* birthday */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <input 
                                value={birthday} 
                                onChange={(e) => setBirthday(e.target.value)} 
                                type="date" 
                                name="birthday" 
                                id="birthday" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="birthday" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Birthday</label>
                        </div>
                        {/* hometown */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <input 
                                value={hometown} 
                                onChange={(e) => setHometown(e.target.value)} 
                                type="text" 
                                name="hometown" 
                                id="hometown" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="hometown" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Kota Kelahiran</label>
                        </div>
                        {/* hireDate */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <input 
                                value={hireDate} 
                                onChange={(e) => setHireDate(e.target.value)} 
                                type="date" 
                                name="hireDate" 
                                id="hireDate" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="hireDate" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Tanggal Masuk Kerja</label>
                        </div>
                        {/* lastEducation */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <input 
                                value={lastEducation} 
                                onChange={(e) => setLastEducation(e.target.value)} 
                                type="text" 
                                name="lastEducation" 
                                id="lastEducation" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" " 
                            />
                            <label 
                                htmlFor="lastEducation" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Pendidikan Terakhir</label>
                        </div>
                        {/* nik */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <input 
                                value={nik} 
                                onChange={(e) => setNik(e.target.value)} 
                                type="text" 
                                name="nik" 
                                id="nik" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 
                                            appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "
                                required
                            />
                            <label 
                                htmlFor="nik" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 
                                            duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium 
                                            peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
                                            peer-focus:scale-75 peer-focus:-translate-y-6"
                            >NIK</label>
                        </div>
                        {/* religion */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <select 
                                required 
                                value={religion} 
                                onChange={(e) => setReligion(e.target.value)} 
                                id="religion_select" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            >
                                <option value="">Choose Religion</option>
                                {religionOptions.map((option) => (
                                    <option key={option.label} value={option.value}>
                                        {option.label} 
                                    </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="religion_select" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Religion</label>
                        </div>
                        {/* gender */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <select 
                                required 
                                value={gender} 
                                onChange={(e) => setGender(e.target.value)} 
                                id="gender_select" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            >
                                <option value="">Choose Gender</option>
                                {genderOptions.map((option) => (
                                    <option key={option.label} value={option.value}>
                                        {option.label} 
                                    </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="gender_select" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Gender</label>
                        </div>
                        {/* marital */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <select 
                                required 
                                value={marital} 
                                onChange={(e) => setMarital(e.target.value)} 
                                id="marital_select" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            >
                                <option value="">Choose Marital</option>
                                {maritalOptions.map((option) => (
                                    <option key={option.label} value={option.value}>
                                        {option.label} 
                                    </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="marital_select" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Marital</label>
                        </div>
                        {/* office */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <select 
                                required 
                                value={office} 
                                onChange={(e) => setOffice(e.target.value)} 
                                id="office_select" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            >
                                <option value="">Choose Office</option>
                                {offices.map((option) => (
                                    <option key={option.slug} value={option.slug}>
                                        {option.name} 
                                    </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="office_select" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Office</label>
                        </div>
                        {/* echelon */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <select 
                                required 
                                value={echelon} 
                                onChange={(e) => setEchelon(e.target.value)} 
                                id="echelon_select" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            >
                                <option value="">Choose Echelon</option>
                                {echelons.map((option) => (
                                    <option key={option.code} value={option.code}>
                                        {option.title} 
                                    </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="echelon_select" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:font-medium peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Echelon</label>
                        </div>
                        {/* status */}
                        <div className="relative z-0 mb-6 w-full md:w-[30%] group">
                            <select required value={status} 
                                onChange={(e) => setStatus(e.target.value)} 
                                id="status_select" 
                                className="block py-2.5 px-2 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            >
                                <option value="">Choose a status</option>
                                {statusOptions.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <label 
                                htmlFor="status_select" 
                                className="absolute top-3 -z-10 text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-focus:left-0 peer-focus:font-medium peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >Status</label>
                        </div>
                        {/* photo */}
                        <div className="mb-6 mt-3 w-full relative z-0 group">
                            <label 
                                className="block mb-2 font-ssp text-sm font-medium text-gray-500" 
                                htmlFor="file_thumbnail"
                            >Upload Photo</label>
                            <input 
                                type="file" 
                                name="photo"
                                ref={photoRef}
                                onChange={(e) => setPhoto(e.target.files[0])}
                                accept='.png,.jpg,.jpeg'
                                className="block w-full text-sm text-slate-500
                                            file:mr-4 file:py-2 file:px-4
                                            file:rounded-full file:border-0
                                            file:text-sm file:font-semibold
                                            file:bg-gray-50 file:text-gray-500
                                            hover:file:bg-gray-100"
                            />
                            <p className="mt-1 font-ssp text-xs text-gray-500 dark:text-gray-300" id="file_photo_help">PNG, JPG, JPEG (MAX. 5MB).</p>
                        
                        </div>
                        {photo && 
                            <div className="mt-3 w-full relative flex flex-row group">
                                <img src={URL.createObjectURL(photo)} alt="Preview" className="max-w-[300px] max-h-[200px] object-cover" />
                                <IoIosCloseCircle 
                                    fontSize='25px' 
                                    className='-mt-3 -ml-3 bg-white rounded-full item-start cursor-pointer' 
                                    onClick={() => handleResetPreview()} />
                            </div>
                        }
                        {/* button */}
                        <div className="mb-6 w-full relative z-0 flex justify-center gap-5">
                            <button 
                                type="submit" 
                                disabled={!isReady}
                                className={`mt-5 px-5 py-2.5 w-fit rounded-lg sm:w-autorounded-lg font-medium text-sm text-center ${isReady ? 'text-white bg-green-400 hover:bg-green-500 cursor-pointer' : 'text-gray-800 bg-gray-300 cursor-wait'}`}
                            >Create</button>
                            <Link 
                                to={'/employees'} 
                                className="px-5 py-2.5 mt-5 w-full sm:w-auto bg-red-400 hover:bg-red-500 rounded-lg
                                            font-medium text-sm text-white text-center cursor-pointer"
                            >Back</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreateEmployee